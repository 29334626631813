<template>
  <div>
    <div class="frmBlock__layout">
      <label class="frmBlock__label" v-for="value in mode" :key="value">
        <input
          type="radio"
          name="mode"
          :value="value"
          @input="updateOption('mode', value)"
          :checked="values.mode === value"
        />
        <span>{{ value }}</span>
      </label>
    </div>
    <div v-show="values.mode === 'text'">
      <div class="frmBlock__layout">
        <TextField
          placeholder="내용을 입력해주세요."
          :value="values.text"
          @input="v => updateOption('text', v)"
        />
      </div>
    </div>
    <div v-show="values.mode === 'image'">
      <div class="frmBlock__title frmBlock__title--last">
        가로 사이즈
        <tooltip
          top="30px"
          :text="`테마에 표시할 로고 사이즈를 지정할 수 있습니다.<br/>
          로고 이미지는 입력하시는 모바일 사이즈의 2배 사이즈로 제작해주세요.<br/>
            ･ 예시) 모바일 가로 사이즈를 “50px”로 입력한 경우 가로 “100px” 이미지로 제작<br/>
            ･ [TIP] 3배 사이즈로 제작하면 다양한 디바이스에서 더욱 선명하게 노출시킬 수 있습니다.<br/>
          가로 사이즈 기준으로 세로 사이즈가 자동으로 리사이즈 됩니다.<br/>
            ･ 확장자 : PNG, SVG`"
        />
      </div>
      <div class="frmBlock__layout frmBlock__layout--dropdown">
        <Dropdown
          :options="widthDropdown.option"
          :value="widthDropdown.value"
          @updateTarget="v => (widthDropdown.value = v)"
        />
      </div>
      <div class="frmBlock__layout frmBlock__layout--number">
        <span class="frmBlock__unit">px</span>
        <input
          type="number"
          class="frmBlock__textField"
          :value="
            widthDropdown.value === 'mbwidth' ? values.mbwidth : values.dtwidth
          "
          min="0"
          @input="updateOption(widthDropdown.value, $event.target.value)"
        />
        <div
          class="frmBlock__counter"
          :class="{'frmBlock__counter--option': $route.path === '/option'}"
        >
          <button
            type="button"
            class="frmBlock__counter--up"
            @click="increaseNumber($event)"
          >
            <span>증가</span>
          </button>
          <button
            type="button"
            class="frmBlock__counter--down"
            @click="decreaseNumber($event)"
          >
            <span>감소</span>
          </button>
        </div>
      </div>
      <div class="frmBlock__layout">
        <ImagePicker
          ref="imagePicker1"
          :index="option.componentOptionId"
          :value="values.img"
          @updateImg="v => updateOption('img', v)"
        />
      </div>
      <div class="frmBlock__title frmBlock__title--last">이미지 설명(alt)</div>
      <div class="frmBlock__layout">
        <TextField
          placeholder="이미지 설명을 입력해주세요."
          :value="values.alt"
          @input="v => updateOption('alt', v)"
        />
      </div>
    </div>
    <div class="frmBlock__title frmBlock__title--last">
      URL
      <tooltip
        top="30px"
        :text="`도메인이 포함된 URL을 입력하는 경우 https:// 또는 http:// 를 함께 입력해주세요.<br/>
        ･ 예) https://qpicki.com`"
      />
    </div>
    <div class="frmBlock__layout frmBlock__layout--grow">
      <TextField
        placeholder="URL을 입력해주세요."
        :value="values.url"
        @input="v => updateOption('url', v)"
      />
      <Dropdown
        :value="values.target"
        @updateTarget="v => updateOption('target', v)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      type: Object
    }
  },
  data() {
    return {
      mode: ["text", "image"],
      widthDropdown: {
        option: [
          {text: "모바일", value: "mbwidth"},
          {text: "PC", value: "dtwidth"}
        ],
        value: "mbwidth"
      },
      values: {}
    };
  },
  computed: {
    // initialValue() {
    //   return this.$route.path === "/option" ? this.values : this.option.value;
    // }
  },
  methods: {
    // 프로퍼티 업데이트 (파트너)
    updateProperty(img, mode, alt, url, target, text, mbwidth, dtwidth) {
      let modified = {
        id: this.option.componentOption.id,
        properties: [
          {
            id: img.id,
            key: img.key,
            initialValue: img.value
          },
          {
            id: mode.id,
            key: mode.key,
            initialValue: mode.value
          },
          {
            id: alt.id,
            key: alt.key,
            initialValue: alt.value
          },
          {
            id: url.id,
            key: url.key,
            initialValue: url.value
          },
          {
            id: target.id,
            key: target.key,
            initialValue: target.value
          },
          {
            id: text.id,
            key: text.key,
            initialValue: text.value
          },
          {
            id: mbwidth.id,
            key: mbwidth.key,
            initialValue: mbwidth.value
          },
          {
            id: dtwidth.id,
            key: dtwidth.key,
            initialValue: dtwidth.value
          }
        ]
      };
      this.$emit("updateProperty", this.option.componentOption.key, modified);
    },
    // 옵션 Update
    updateOption(type, value) {
      this.$set(this.values, type, value);
      this.$emit("updateOption", this.option.componentOption.key, type, value);

      if (this.$route.path === "/option") {
        let mProperties = this.option.modified.properties;
        let obj = {};

        mProperties.forEach(item => {
          obj[item.key] = {
            id: item.id,
            key: item.key,
            value: type === item.key ? value : item.initialValue
          };
        });

        this.updateProperty(
          obj.img,
          obj.mode,
          obj.alt,
          obj.url,
          obj.target,
          obj.text,
          obj.mbwidth,
          obj.dtwidth
        );
      }
    },
    // number increase
    increaseNumber(e) {
      e.currentTarget.parentNode.previousSibling.focus();
      const widthValue = this.values[this.widthDropdown.value];
      let value = Number(widthValue) + 1;
      this.updateOption(this.widthDropdown.value, value);
    },
    // number decrease
    decreaseNumber(e) {
      e.currentTarget.parentNode.previousSibling.focus();
      const widthValue = this.values[this.widthDropdown.value];
      if (Number(widthValue) > 0) {
        let value = Number(widthValue) - 1;
        this.updateOption(this.widthDropdown.value, value);
      }
    },
    //값 추출
    extractValue() {
      let isOption = this.$route.path === "/option" ? true : false;
      if (!isOption) {
        this.values = this.option.value;
        return false;
      }
      let obj = {};

      this.option.componentOption.properties.forEach(item => {
        switch (item.key) {
          case "mode":
            this.$set(this.values, item.key, item.initialValue ?? "text");
            obj[item.key] = {
              id: item.id,
              key: item.key,
              value: item.initialValue ?? "text"
            };
            break;

          case "target":
            this.$set(this.values, item.key, item.initialValue ?? "_self");
            obj[item.key] = {
              id: item.id,
              key: item.key,
              value: item.initialValue ?? "_self"
            };
            break;

          case "img":
          case "alt":
          case "url":
          case "text":
            this.$set(this.values, item.key, item.initialValue);
            obj[item.key] = {
              id: item.id,
              key: item.key,
              value: item.initialValue
            };
            break;

          case "mbwidth":
          case "dtwidth":
            this.$set(this.values, item.key, item.initialValue ?? 100);
            obj[item.key] = {
              id: item.id,
              key: item.key,
              value: item.initialValue ?? 100
            };
            break;
        }
      });
      this.updateProperty(
        obj.img,
        obj.mode,
        obj.alt,
        obj.url,
        obj.target,
        obj.text,
        obj.mbwidth,
        obj.dtwidth
      );
    }
  },
  mounted() {
    // localStorage.setItem(`imgOptId${this.option.id}`, this.option.value.img);
  },
  created() {
    // updateImage 구독
    this.$EventBus.$on(`updateImage${this.option.componentOptionId}`, param => {
      // localStorage.setItem(`imgOptId${this.option.id}`, param.value);
      if (param.type === "change" && this.$refs.imagePicker1) {
        this.$refs.imagePicker1.imageRead(param.value);
      }
      this.updateOption("img", param.value);
    });

    this.extractValue();
  }
};
</script>

<style lang="scss" scoped>
.frmBlock__layout .frmBlock__label {
  flex: auto;

  span {
    text-transform: capitalize;
  }
}
.frmBlock__layout {
  &--dropdown {
    display: inline-block;
    width: 128px;

    .frmBlock__dropdownField {
      width: 100%;
    }
  }
  &--number {
    display: inline-block;
    width: 120px;
    margin-left: 16px;

    .frmBlock__counter {
      position: absolute;
      top: 4px;
      left: 8px;
      width: 24px;
    }
    .frmBlock__counter button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 20px;
    }
    .frmBlock__counter--up {
      border-radius: 4px 4px 0px 0px;
    }
    .frmBlock__counter--down {
      border-radius: 0px 0px 4px 4px;
    }
    .frmBlock__counter--up:after,
    .frmBlock__counter--down:after {
      color: #797979;
      transform: scale(0.5, 0.4);
    }
    .frmBlock__unit {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 34px;
      color: #8d8d8d;
      font-size: 14px;
    }
    .frmBlock__textField[type="number"]:enabled:focus
      + .frmBlock__counter
      button,
    .frmBlock__textField[type="number"]:enabled:hover
      + .frmBlock__counter
      button {
      background-color: #d2f8ec;
      background-repeat: no-repeat;
      background-size: 8px auto;
    }
    .frmBlock__textField[type="number"]:enabled:focus
      + .frmBlock__counter
      button:after,
    .frmBlock__textField[type="number"]:enabled:hover
      + .frmBlock__counter
      button:after {
      color: #54c7a2;
    }
    .frmBlock__textField[type="number"]:disabled
      + .frmBlock__counter
      button:after,
    .frmBlock__textField[type="number"]:disabled
      + .frmBlock__counter
      button:after {
      color: #dcdcdc;
    }
    .frmBlock__counter--up:enabled:hover:after,
    .frmBlock__counter--down:enabled:hover:after {
      color: #309574 !important;
    }
    .frmBlock__counter span {
      text-indent: -9999px;
    }
    .frmBlock__counter--up:after {
      content: "\25B2";
      margin-top: 3px;
    }
    .frmBlock__counter--down:after {
      content: "\25BC";
      margin-top: -3px;
    }
    /* 반응형 - mobile */
    @media screen and (max-width: 1023px) {
      .frmBlock__counter {
        display: none;
        &--option {
          display: block;
        }
      }
    }
  }
}
</style>
