var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "frmBlock__layout"
  }, _vm._l(_vm.mode, function (value) {
    return _c('label', {
      key: value,
      staticClass: "frmBlock__label"
    }, [_c('input', {
      attrs: {
        "type": "radio",
        "name": "mode"
      },
      domProps: {
        "value": value,
        "checked": _vm.values.mode === value
      },
      on: {
        "input": function input($event) {
          return _vm.updateOption('mode', value);
        }
      }
    }), _c('span', [_vm._v(_vm._s(value))])]);
  }), 0), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.values.mode === 'text',
      expression: "values.mode === 'text'"
    }]
  }, [_c('div', {
    staticClass: "frmBlock__layout"
  }, [_c('TextField', {
    attrs: {
      "placeholder": "내용을 입력해주세요.",
      "value": _vm.values.text
    },
    on: {
      "input": function input(v) {
        return _vm.updateOption('text', v);
      }
    }
  })], 1)]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.values.mode === 'image',
      expression: "values.mode === 'image'"
    }]
  }, [_c('div', {
    staticClass: "frmBlock__title frmBlock__title--last"
  }, [_vm._v(" 가로 사이즈 "), _c('tooltip', {
    attrs: {
      "top": "30px",
      "text": "\uD14C\uB9C8\uC5D0 \uD45C\uC2DC\uD560 \uB85C\uACE0 \uC0AC\uC774\uC988\uB97C \uC9C0\uC815\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.<br/>\n        \uB85C\uACE0 \uC774\uBBF8\uC9C0\uB294 \uC785\uB825\uD558\uC2DC\uB294 \uBAA8\uBC14\uC77C \uC0AC\uC774\uC988\uC758 2\uBC30 \uC0AC\uC774\uC988\uB85C \uC81C\uC791\uD574\uC8FC\uC138\uC694.<br/>\n          \uFF65 \uC608\uC2DC) \uBAA8\uBC14\uC77C \uAC00\uB85C \uC0AC\uC774\uC988\uB97C \u201C50px\u201D\uB85C \uC785\uB825\uD55C \uACBD\uC6B0 \uAC00\uB85C \u201C100px\u201D \uC774\uBBF8\uC9C0\uB85C \uC81C\uC791<br/>\n          \uFF65 [TIP] 3\uBC30 \uC0AC\uC774\uC988\uB85C \uC81C\uC791\uD558\uBA74 \uB2E4\uC591\uD55C \uB514\uBC14\uC774\uC2A4\uC5D0\uC11C \uB354\uC6B1 \uC120\uBA85\uD558\uAC8C \uB178\uCD9C\uC2DC\uD0AC \uC218 \uC788\uC2B5\uB2C8\uB2E4.<br/>\n        \uAC00\uB85C \uC0AC\uC774\uC988 \uAE30\uC900\uC73C\uB85C \uC138\uB85C \uC0AC\uC774\uC988\uAC00 \uC790\uB3D9\uC73C\uB85C \uB9AC\uC0AC\uC774\uC988 \uB429\uB2C8\uB2E4.<br/>\n          \uFF65 \uD655\uC7A5\uC790 : PNG, SVG"
    }
  })], 1), _c('div', {
    staticClass: "frmBlock__layout frmBlock__layout--dropdown"
  }, [_c('Dropdown', {
    attrs: {
      "options": _vm.widthDropdown.option,
      "value": _vm.widthDropdown.value
    },
    on: {
      "updateTarget": function updateTarget(v) {
        return _vm.widthDropdown.value = v;
      }
    }
  })], 1), _c('div', {
    staticClass: "frmBlock__layout frmBlock__layout--number"
  }, [_c('span', {
    staticClass: "frmBlock__unit"
  }, [_vm._v("px")]), _c('input', {
    staticClass: "frmBlock__textField",
    attrs: {
      "type": "number",
      "min": "0"
    },
    domProps: {
      "value": _vm.widthDropdown.value === 'mbwidth' ? _vm.values.mbwidth : _vm.values.dtwidth
    },
    on: {
      "input": function input($event) {
        return _vm.updateOption(_vm.widthDropdown.value, $event.target.value);
      }
    }
  }), _c('div', {
    staticClass: "frmBlock__counter",
    class: {
      'frmBlock__counter--option': _vm.$route.path === '/option'
    }
  }, [_c('button', {
    staticClass: "frmBlock__counter--up",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.increaseNumber($event);
      }
    }
  }, [_c('span', [_vm._v("증가")])]), _c('button', {
    staticClass: "frmBlock__counter--down",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.decreaseNumber($event);
      }
    }
  }, [_c('span', [_vm._v("감소")])])])]), _c('div', {
    staticClass: "frmBlock__layout"
  }, [_c('ImagePicker', {
    ref: "imagePicker1",
    attrs: {
      "index": _vm.option.componentOptionId,
      "value": _vm.values.img
    },
    on: {
      "updateImg": function updateImg(v) {
        return _vm.updateOption('img', v);
      }
    }
  })], 1), _c('div', {
    staticClass: "frmBlock__title frmBlock__title--last"
  }, [_vm._v("이미지 설명(alt)")]), _c('div', {
    staticClass: "frmBlock__layout"
  }, [_c('TextField', {
    attrs: {
      "placeholder": "이미지 설명을 입력해주세요.",
      "value": _vm.values.alt
    },
    on: {
      "input": function input(v) {
        return _vm.updateOption('alt', v);
      }
    }
  })], 1)]), _c('div', {
    staticClass: "frmBlock__title frmBlock__title--last"
  }, [_vm._v(" URL "), _c('tooltip', {
    attrs: {
      "top": "30px",
      "text": "\uB3C4\uBA54\uC778\uC774 \uD3EC\uD568\uB41C URL\uC744 \uC785\uB825\uD558\uB294 \uACBD\uC6B0 https:// \uB610\uB294 http:// \uB97C \uD568\uAED8 \uC785\uB825\uD574\uC8FC\uC138\uC694.<br/>\n      \uFF65 \uC608) https://qpicki.com"
    }
  })], 1), _c('div', {
    staticClass: "frmBlock__layout frmBlock__layout--grow"
  }, [_c('TextField', {
    attrs: {
      "placeholder": "URL을 입력해주세요.",
      "value": _vm.values.url
    },
    on: {
      "input": function input(v) {
        return _vm.updateOption('url', v);
      }
    }
  }), _c('Dropdown', {
    attrs: {
      "value": _vm.values.target
    },
    on: {
      "updateTarget": function updateTarget(v) {
        return _vm.updateOption('target', v);
      }
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }